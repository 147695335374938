import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"  viewBox="0 0 558.000000 392.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,392.000000) scale(0.100000,-0.100000)">
<path d="M1769 2750 c-413 -52 -747 -362 -845 -785 -28 -118 -26 -339 4 -459
24 -96 94 -252 135 -301 l25 -30 140 -3 140 -3 30 28 c17 15 33 42 36 58 5 26
-1 38 -42 82 -319 347 -173 887 283 1044 60 21 88 24 200 23 143 0 201 -14
319 -77 79 -42 191 -149 246 -235 56 -89 80 -102 192 -102 99 0 127 12 152 64
17 34 17 43 6 75 -55 151 -157 299 -276 401 -105 90 -244 161 -380 195 -106
27 -264 38 -365 25z m397 -49 c201 -60 380 -188 498 -356 50 -71 130 -230 122
-243 -7 -12 -226 -2 -226 10 0 24 -77 135 -132 190 -130 131 -289 204 -469
215 -258 16 -495 -101 -639 -315 -87 -130 -126 -274 -117 -435 10 -173 68
-315 181 -443 l47 -54 -135 0 c-159 0 -151 -5 -213 126 -72 152 -88 231 -87
424 0 159 1 168 33 260 90 264 236 437 466 553 156 78 249 97 450 92 117 -2
166 -8 221 -24z"/>
<path d="M1793 2112 c-47 -3 -56 -7 -80 -39 l-28 -36 -2 -501 c-1 -276 0 -514
4 -529 12 -61 19 -62 436 -66 445 -4 480 -1 519 41 27 29 28 34 27 127 -1 71
-3 86 -9 58 -4 -21 -5 -48 -3 -60 2 -12 1 -32 -4 -45 -8 -21 -10 -21 -30 -7
-13 9 -25 32 -29 53 -4 20 -12 40 -18 44 -6 4 -151 8 -322 8 l-310 0 -22 25
c-13 14 -27 23 -33 19 -8 -4 -9 3 -4 25 5 24 3 31 -9 31 -9 0 -16 5 -16 10 0
6 7 10 15 10 13 0 15 46 13 373 l-3 372 -26 3 c-15 2 -35 12 -45 23 -26 29 -6
39 81 39 43 0 64 4 55 9 -20 11 -94 17 -157 13z"/>
<path d="M1958 2051 c-5 -18 -8 -212 -8 -431 0 -350 2 -399 16 -404 8 -3 171
-5 362 -5 303 0 345 2 328 15 -16 11 -78 14 -337 14 -275 0 -320 2 -333 16
-13 13 -16 42 -16 163 0 82 3 169 6 193 l6 46 28 -32 28 -31 234 -5 233 -5 3
-27 c2 -15 -10 -57 -27 -95 -51 -118 -30 -143 122 -143 142 0 167 23 214 190
26 94 41 264 27 326 -7 33 -9 20 -15 -76 -6 -113 -33 -241 -64 -301 l-16 -29
-109 0 c-61 0 -110 3 -110 6 0 3 8 18 18 32 26 38 72 167 72 203 l0 29 -268 0
c-148 0 -271 3 -274 6 -3 3 4 31 17 62 13 31 29 71 35 89 7 18 15 32 19 31 3
0 159 0 346 1 224 1 335 5 325 11 -9 6 -155 10 -361 10 -334 0 -346 -1 -367
-20 -28 -26 -55 -79 -78 -152 -15 -49 -27 -68 -36 -55 -1 1 -4 92 -7 202 -4
125 -9 187 -13 166z"/>
<path d="M4575 1957 c-10 -8 -18 -34 -21 -74 -5 -54 -3 -67 19 -104 24 -39 26
-40 36 -21 6 11 11 52 11 91 l0 71 35 0 c25 0 35 4 35 15 0 17 -8 21 -62 28
-20 3 -43 0 -53 -6z"/>
<path d="M3085 1941 c-122 -55 -169 -205 -99 -311 46 -69 79 -82 210 -86 67
-2 115 0 119 6 3 6 -4 11 -17 12 -42 2 -47 3 -54 10 -4 4 -33 5 -64 1 -48 -5
-64 -2 -98 17 -105 59 -111 228 -10 303 28 21 110 34 152 23 13 -3 39 -20 56
-37 23 -24 34 -29 42 -21 9 9 5 19 -13 38 -58 62 -147 80 -224 45z"/>
<path d="M3356 1938 c-11 -15 -16 -45 -16 -85 l0 -63 -35 0 c-39 0 -44 10 -18
36 18 18 16 17 -29 -5 -23 -12 -29 -11 -43 3 -9 9 -33 16 -54 16 -30 0 -40 -5
-54 -29 -30 -52 -17 -133 27 -163 19 -13 53 -9 77 10 l22 19 -26 7 c-22 5 -27
12 -27 36 l0 30 70 0 70 0 0 -90 c0 -70 3 -90 14 -90 8 0 16 -7 20 -15 6 -17
116 -22 116 -5 0 6 -6 10 -12 11 -7 1 -24 2 -38 2 l-25 2 -3 178 -2 177 106 0
106 0 34 -34 c29 -29 34 -41 35 -82 0 -43 1 -46 10 -24 14 34 4 76 -27 117
-40 53 -70 63 -183 63 -92 0 -101 -2 -115 -22z m-186 -130 c0 -13 -49 -95 -54
-91 -8 8 -8 78 1 86 6 6 53 10 53 5z"/>
<path d="M3719 1943 c-10 -11 -15 -58 -17 -170 -2 -84 -7 -153 -10 -153 -7 0
-26 27 -49 70 -7 14 -13 19 -13 11 0 -8 15 -42 34 -77 19 -34 33 -64 31 -66
-3 -2 -25 -2 -50 2 -43 5 -49 9 -83 60 -24 35 -45 56 -59 58 -22 3 -23 0 -21
-55 1 -35 3 -45 5 -25 2 17 7 32 11 31 4 0 21 -19 37 -42 l31 -42 217 -3 c138
-1 217 1 217 8 0 5 -51 11 -122 12 l-123 3 -3 178 -2 177 109 0 c118 0 141 4
141 26 0 11 -26 14 -134 14 -112 0 -136 -3 -147 -17z"/>
<path d="M4022 1948 c-7 -7 -12 -21 -12 -32 -1 -62 -6 -66 -92 -66 l-79 0 3
-32 c3 -33 3 -33 62 -36 66 -3 93 11 63 32 -16 13 -15 14 12 18 26 4 31 1 35
-21 3 -14 3 -39 2 -56 -2 -16 -4 -44 -4 -61 -2 -28 -4 -30 -34 -26 -28 4 -29
6 -14 17 15 11 15 13 0 19 -20 8 -88 8 -108 0 -9 -3 -16 -16 -16 -29 0 -38 23
-48 100 -43 l70 5 0 -29 c0 -19 8 -37 23 -49 19 -16 40 -18 155 -19 169 0 178
18 10 22 l-123 3 -3 178 -2 177 112 0 c115 0 158 10 128 30 -22 14 -273 13
-288 -2z"/>
<path d="M4331 1941 c-6 -11 -11 -36 -11 -55 l0 -36 -81 0 -80 0 3 -32 c3 -32
5 -33 52 -36 65 -4 80 2 66 28 -9 18 -8 20 15 20 25 0 25 -1 25 -80 0 -79 0
-80 -25 -80 -14 0 -25 5 -25 10 0 6 8 10 18 10 16 1 16 1 -1 11 -9 5 -41 9
-70 7 -51 -3 -52 -4 -55 -35 -3 -28 1 -33 23 -39 14 -3 50 -4 80 -2 54 5 55 4
55 -22 0 -43 38 -70 98 -70 63 0 79 18 20 22 l-43 3 -3 178 -2 177 26 0 c19 0
25 4 22 18 -2 13 -15 18 -50 20 -38 2 -49 -1 -57 -17z"/>
<path d="M3484 1844 c-6 -3 -8 -21 -5 -47 6 -41 7 -42 45 -42 48 0 65 21 50
62 -8 23 -16 28 -44 30 -19 1 -40 0 -46 -3z m36 -34 c0 -5 -4 -10 -10 -10 -5
0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"/>
<path d="M4480 1705 c0 -26 15 -47 91 -127 32 -33 42 -38 82 -38 25 0 49 5 52
10 4 6 -8 10 -29 10 -32 0 -42 8 -102 78 -79 93 -94 103 -94 67z"/>
<path d="M3475 1462 c-6 -4 -21 -20 -33 -36 -20 -27 -22 -39 -22 -176 l0 -148
31 -26 31 -26 201 2 c199 3 202 3 212 26 5 12 13 22 17 22 5 0 8 18 8 40 0 33
4 41 24 46 13 3 37 4 52 2 26 -3 30 -8 35 -46 8 -62 35 -85 108 -90 33 -2 81
-1 105 2 37 6 48 13 61 38 22 45 20 94 -5 101 -24 6 -27 42 -4 51 13 5 14 16
8 62 -4 35 -3 59 4 63 10 6 15 30 13 68 -1 19 -8 22 -78 29 -59 5 -88 3 -120
-8 -28 -10 -45 -12 -52 -5 -6 6 -55 11 -110 11 -81 1 -102 -2 -118 -16 -24
-22 -33 -23 -33 -4 0 15 -61 30 -88 21 -40 -13 -57 -56 -62 -160 -5 -97 -6
-100 -31 -109 -55 -19 -59 -9 -59 133 0 125 -1 129 -22 135 -31 8 -61 7 -73
-2z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
